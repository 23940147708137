import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { App } from "./App";
import "./index.css";
import "./features/utils/i18n";
import { Spinner } from "./ui/atoms/Spinner";

const history = createBrowserHistory();
ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Suspense
                fallback={
                    <div className="flex flex-1 min-h-screen justify-center items-center">
                        <Spinner />
                    </div>
                }
            >
                <App />
            </Suspense>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
