import { observer } from "mobx-react-lite";
import { FC, useContext, lazy } from "react";

import { authStore } from "./features/auth/store/AuthStore";

const AuthRoutes = lazy(() =>
    import("./features/auth/routes").then(module => ({
        default: module.authRoutes
    }))
);
const AppRoutes = lazy(() =>
    import("./routes").then(module => ({
        default: module.rootRoutes
    }))
);

export const App: FC = observer(() => {
    const auth = useContext(authStore);
    if (auth.sessionId === null) {
        return <AuthRoutes />;
    }
    if (auth.user && auth.user.role === "user") {
        auth.logout();
    }
    return <AppRoutes />;
});
