import Lottie from "react-lottie-player";
import animationData from "./animation.json";

export const Spinner = () => (
    <Lottie
        loop={true}
        play={true}
        animationData={animationData}
        rendererSettings={{ className: "cursor-default" }}
        style={{ height: 15, width: 50 }}
    />
);
